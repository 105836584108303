@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:200,300,500|Rubik:300,400,500&subset=thai&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bai+Jamjuree:300,400,500&display=swap&subset=latin-ext,thai);
@font-face {
  font-family: 'Anuphan';
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Anuphan-Regular.22b4d3b5.eot);
  src: url(/static/media/Anuphan-Regular.22b4d3b5.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Anuphan-Regular.8b1f7396.woff2) format('woff2'),
    url(/static/media/Anuphan-Regular.e0ae7ca2.woff) format('woff'),
    url(/static/media/Anuphan-Regular.b9f4f346.ttf) format('truetype'),
    url(/static/media/Anuphan-Regular.84d4cbb7.svg#Anuphan) format('svg');
}

@font-face {
  font-family: 'Anuphan';
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/Anuphan-Medium.c60a08e5.eot);
  src: url(/static/media/Anuphan-Medium.c60a08e5.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Anuphan-Medium.dcdb997f.woff2) format('woff2'),
    url(/static/media/Anuphan-Medium.307a7242.woff) format('woff'),
    url(/static/media/Anuphan-Medium.8515d44b.ttf) format('truetype'),
    url(/static/media/Anuphan-Medium.a6013af1.svg#Anuphan) format('svg');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/Anuphan-SemiBold.c9569649.eot);
  src: url(/static/media/Anuphan-SemiBold.c9569649.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Anuphan-SemiBold.465bfa68.woff2) format('woff2'),
    url(/static/media/Anuphan-SemiBold.8ed71a02.woff) format('woff'),
    url(/static/media/Anuphan-SemiBold.1361ef5e.ttf) format('truetype'),
    url(/static/media/Anuphan-SemiBold.496bc1a4.svg#Anuphan) format('svg');
}

