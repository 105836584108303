@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Kanit:200,300,500|Rubik:300,400,500&subset=thai&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bai+Jamjuree:300,400,500&display=swap&subset=latin-ext,thai');

@font-face {
  font-family: 'Anuphan';
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Anuphan/Anuphan-Regular.eot');
  src: url('./fonts/Anuphan/Anuphan-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Anuphan/Anuphan-Regular.woff2') format('woff2'),
    url('./fonts/Anuphan/Anuphan-Regular.woff') format('woff'),
    url('./fonts/Anuphan/Anuphan-Regular.ttf') format('truetype'),
    url('./fonts/Anuphan/Anuphan-Regular.svg#Anuphan') format('svg');
}

@font-face {
  font-family: 'Anuphan';
  font-display: swap;
  font-weight: 500;
  src: url('./fonts/Anuphan/Anuphan-Medium.eot');
  src: url('./fonts/Anuphan/Anuphan-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Anuphan/Anuphan-Medium.woff2') format('woff2'),
    url('./fonts/Anuphan/Anuphan-Medium.woff') format('woff'),
    url('./fonts/Anuphan/Anuphan-Medium.ttf') format('truetype'),
    url('./fonts/Anuphan/Anuphan-Medium.svg#Anuphan') format('svg');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 600;
  font-display: swap;
  src: url('./fonts/Anuphan/Anuphan-SemiBold.eot');
  src: url('./fonts/Anuphan/Anuphan-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Anuphan/Anuphan-SemiBold.woff2') format('woff2'),
    url('./fonts/Anuphan/Anuphan-SemiBold.woff') format('woff'),
    url('./fonts/Anuphan/Anuphan-SemiBold.ttf') format('truetype'),
    url('./fonts/Anuphan/Anuphan-SemiBold.svg#Anuphan') format('svg');
}
